import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
import HubspotForm from "react-hubspot-form"

const ContactCollaboration = ({ location }) => {
  return (
    <Layout lang="en-US">
      <Seo
        pagetitle="Cooperation and Sales Inquiries"
        pagepath={location.pathname}
        pagedesc="Cooperation and Sales Inquiries | PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
      />
      <Breadcrumb title="Cooperation and Sales Inquiries" />
      <main className="information">
        <section className="information__section l-container">
          {/* <h1 className="information__title"></h1> */}
          <HubspotForm
            portalId="8283520"
            formId="0e45e99d-303b-4be4-b610-ee4cf28e13db"
            loading={<div>Loading...</div>}
          />
        </section>
      </main>
    </Layout>
  )
}

export default ContactCollaboration
